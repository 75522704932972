import { initializeConnector } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import { WalletConnect } from "@web3-react/walletconnect";
import { ethers } from "ethers";

export const [metaMask, metaMaskHooks] = initializeConnector(
  actions =>
    new MetaMask({
      actions,
      options: {
        rpc: {
          17772:
            "https://testnet.heroesofnft.com/ext/bc/2KV1ighhTjNpuQq8BVgHeJF3QHdF3KxhY9AqB9M1GfUuBCKjNo/rpc",
        },
      },
    })
);

export const [walletConnect, walletConnectHooks] = initializeConnector(
  actions =>
    new WalletConnect({
      actions,
      options: {
        rpc: {
          17772:
            "https://testnet.heroesofnft.com/ext/bc/2KV1ighhTjNpuQq8BVgHeJF3QHdF3KxhY9AqB9M1GfUuBCKjNo/rpc",
        },
      },
    })
);

const switchRequest = () => {
  const { ethereum } = window;
  return ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: ethers.utils.hexlify(43113) }],
  });
};

export const switchNetwork = async () => {
  const { ethereum } = window;
  if (ethereum) {
    try {
      await switchRequest();
    } catch (error) {
      console.log(error);
    }
  }
};
/* export const handleLogin = async (
  signer,
  dispatch,
  setOpenRegister,
  handleClose,
  setTransaction,
  miss,
  connectorName
) => {
  try {
    const user_address = await signer.getAddress();
    axios.get(`${AUTH_BASE_URL}/user/${user_address}`).then(body => {
      const _user = body.data.user;
      if (_user) {
        handleSignMessage({
          signer: signer,
          publicAddress: _user.publicAddress,
          nonce: _user.nonce,
          dispatch,
          handleClose,
          setTransaction,
          miss,
          connectorName,
        });
        return _user;
      } else {
        setTransaction({
          loading: false,
          status: "pending",
        });

        setOpenRegister(true);
      }
    });
  } catch (error) {
    console.log("REJECTED !");
  }
}; */

/* const handleSignMessage = async ({
  signer,
  publicAddress,
  nonce,
  dispatch,
  handleClose,
  setTransaction,
  miss,
  connectorName,
}) => {
  try {
    const signature = await signer.signMessage(`I am signing my one-time nonce: ${nonce}`);

    handleAuthenticate({
      publicAddress: publicAddress,
      signature: signature,
      dispatch,
      handleClose,
      setTransaction,
      miss,
      connectorName,
    });
    setTransaction({
      loading: true,
      status: "success",
      message: "Wallet connected successfully.",
    });

    setTimeout(
      () =>
        setTransaction({
          loading: false,
          status: "success",
        }),
      2000
    );
    handleClose();
  } catch (error) {
    setTransaction({
      loading: false,
      status: "pending",
    });
    handleClose();
    console.log(error);
  }
}; */

/* const handleAuthenticate = async ({
  publicAddress,
  signature,
  dispatch,
  setTransaction,
  miss,
  connectorName,
}) => {
  axios
    .post(`${AUTH_BASE_URL}/user/login`, {
      publicAddress: publicAddress,
      signature: signature,
    })
    .then(async body => {
      var { exp } = jwt_decode(body.data.token);
      const _tokenExpiration = exp * 1000 - 300000;
      dispatch({
        type: actionTypes.USER_CHANGED_TOKEN,
        payload: {
          publicAddress: body.data.user.publicAddress,
          token: body.data.token,
          tokenExpiration: _tokenExpiration,
          username: body.data.user.username,
          referralCode: body.data.user.referralCode,
        },
      });
      if (!miss)
        dispatch(
          setWalletConnected({
            connectorName,
            walletConnected: true,
          })
        );
    });
}; */

/* export const clickConnectWalletHandler = (
  signer,
  addr,
  dispatch,
  setOpenRegisterModal,
  handleClose,
  setTransaction,
  miss,
  connectorName
) => {
  if (signer && addr) {
    handleLogin(
      signer,
      dispatch,
      setOpenRegisterModal,
      handleClose,
      setTransaction,
      miss,
      connectorName
    );
  }
}; */

// export const getUserName = async (publicAddress, setUserAuth) => {
//   try {
//     const body = await axios.get(`${AUTH_BASE_URL}/user/${publicAddress}`);
//     const _user = body.data.user;
//     if (_user) {
//       setUserAuth({ userName: _user.username });
//       return _user.username;
//     }
//   } catch (error) {
//     console.log(error);
//   }
// };
