import { create } from "zustand";
import { persist } from "zustand/middleware";

const useNetworkStore = create(
  persist(
    (set, get) => ({
      isWalletConnected: false,
      contract: null,
      chain: null,
      selectedProvider: null,
      updateWalletConnected: value => set(state => ({ isWalletConnected: value })),
      connectWallet: () => {
        if (state.selectedProvider) {
          throw new Error("Provider is not exists.");
        }
        set(state => state);
      },
      setSelectedProvider: providerName => set(state => ({ selectedProvider: providerName })),
    }),
    {
      name: "persistent-network-storage",
    }
  )
);

export default useNetworkStore;
