import { CAMERA_ACTIONS } from "../Constant";
import { ethers } from "ethers";
import { useLayoutEffect } from "react";
import { Group, MeshBasicMaterial } from "three";
import ScrollTrigger from "gsap/ScrollTrigger";
/**
 * @typedef {Object} CameraAction
 * @property {string} name - The name of the action
 * @property {boolean} backward
 */
/**
 * preview tier room animation
 */
const getInCameraAction = (tier, backward = false) => ({
  name: CAMERA_ACTIONS[`camera_in_tier${tier}`],
  backward,
});
/**
 *
 * @param {*} tier
 * @param {*} backward
 * @returns
 * into preview animation
 */
const getIntoCameraAction = (tier, backward = false) => ({
  name: CAMERA_ACTIONS[`camera_into_tier${tier}`],
  backward,
});
const getScrollCameraAction = () => ({ name: CAMERA_ACTIONS[`camera_scroll`], backward: false });
/**
 *
 * @param {number} tier
 * @param {boolean} backward
 * @returns {void}
 * from scroll camera to tier view animation
 */
const getToCameraAction = (tier, backward = false) => ({
  name: CAMERA_ACTIONS[`camera_to_tier${tier}`],
  backward,
});
const getWaitingCameraAction = (tier, backward = false) => ({
  name: CAMERA_ACTIONS[`camera_waiting_tier${tier}`],
  backward,
});
const isScrollCamera = action => Boolean(action.name.includes("scroll"));
const isTo = action => Boolean(action.name.includes("_to_"));
const isInto = action => Boolean(action.name.includes("_into_"));
const isWaiting = action => Boolean(action.name.includes("_waiting_"));
const isIn = action => Boolean(action.name.includes("_in_"));

const convertToWei = value => {
  const val = ethers.utils.parseUnits(value.toString(), "ether");
  return val.toString();
};
const addMinutes = (dt, minutes) => {
  return new Date(dt.getTime() + minutes * 60000);
};

const getTextColorByTierId = tierId => {
  let textColor;

  switch (tierId) {
    case 1:
      textColor = "#f9bb02";
      break;
    case 2:
      textColor = "#971aaf";
      break;
    case 3:
      textColor = "#00a8ff";
      break;
    case 4:
      textColor = "#00ff00";
      break;
    default:
      textColor = "#ffffff";
      break;
  }
  return textColor;
};

var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
  window.addEventListener(
    "test",
    null,
    Object.defineProperty({}, "passive", {
      get: function () {
        supportsPassive = true;
      },
    })
  );
} catch (e) {}

var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent = "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

// call this to Disable
/* function disableScroll(element) {
  element.addEventListener("DOMMouseScroll", preventDefault, false); // older FF
  element.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  element.addEventListener("touchmove", preventDefault, wheelOpt); // mobile
  element.addEventListener("keydown", preventDefaultForScrollKeys, false);
} */

// call this to Enable
/* function enableScroll(element) {
  element.removeEventListener("DOMMouseScroll", preventDefault, false);
  element.removeEventListener(wheelEvent, preventDefault, wheelOpt);
  element.removeEventListener("touchmove", preventDefault, wheelOpt);
  element.removeEventListener("keydown", preventDefaultForScrollKeys, false);
} */

const enableScroll = () => {
  document.body.classList.remove("no-scroll");
  document.documentElement.classList.remove("no-scroll");
  if (ScrollTrigger.isTouch > 0) {
    ScrollTrigger.normalizeScroll(true);
  }
};

const disableScroll = () => {
  document.body.classList.add("no-scroll");
  document.documentElement.classList.add("no-scroll");
  if (ScrollTrigger.isTouch > 0) {
    ScrollTrigger.normalizeScroll(false);
  }
};

function includes(str, arr) {
  if (typeof arr === "string") return str.includes(arr);
  if (Array.isArray(arr)) return arr.some(ex => str.includes(ex));
}
/**
 *
 * @param {string} objectName
 * @returns
 */
function getBucketUrl(objectName) {
  const bucket = process.env.REACT_APP_S3_BUCKET_URL;
  if (objectName.startsWith("/") === false) return bucket + "/" + objectName;
  return bucket + objectName;
}
/**
 *
 * @param {T[]} array
 */
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export {
  getInCameraAction,
  getIntoCameraAction,
  getScrollCameraAction,
  getToCameraAction,
  getWaitingCameraAction,
  isScrollCamera,
  isTo,
  isInto,
  isWaiting,
  isIn,
  convertToWei,
  addMinutes,
  getTextColorByTierId,
  disableScroll,
  enableScroll,
  includes,
  getBucketUrl,
  shuffleArray,
};
