import { gql } from "@apollo/client";

export const OWNER_OFS = gql`
  query {
    ownerOfs {
      nodes {
        id
        owner
      }
    }
  }
`;

export const OWNER_OF = gql`
  query {
    ownerOf(id: $tokenId) {
      id
      owner
    }
  }
`;
