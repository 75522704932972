import { metaMask, metaMaskHooks, walletConnect, walletConnectHooks } from "../Utils/connector";
import { useEffect, useState } from "react";
import useNetworkStore from "../Store/NetworkStore";
import { connectorNames } from "../Constant";
import { useWeb3React } from "@web3-react/core";

export const useConnector = () => {
  const metamaskProvider = metaMaskHooks.useProvider();
  const metamaskAccount = metaMaskHooks.useAccount();
  const metaMaskChainId = metaMaskHooks.useChainId();

  const walletConnectProvider = walletConnectHooks.useProvider();
  const walletConnectAccount = walletConnectHooks.useAccount();
  const walletConnectChainId = walletConnectHooks.useChainId();

  const { isActivating } = useWeb3React();

  const [w3Context, setWeb3Context] = useState({
    provider: null,
    account: null,
    chainId: null,
    signer: null,
  });

  const { isWalletConnected, selectedProvider } = useNetworkStore(state => ({
    isWalletConnected: state.isWalletConnected,
    selectedProvider: state.selectedProvider,
  }));

  useEffect(() => {
    if (selectedProvider === connectorNames.metamask) {
      setWeb3Context({
        provider: metamaskProvider,
        account: metamaskAccount,
        chainId: metaMaskChainId,
        signer: metamaskProvider?.getSigner(metamaskAccount),
      });
    } else if (selectedProvider === connectorNames.walletconnect) {
      setWeb3Context({
        provider: walletConnectProvider,
        account: walletConnectAccount,
        chainId: walletConnectChainId,
        signer: walletConnectProvider?.getSigner(walletConnectAccount),
      });
    }
  }, [selectedProvider, isActivating]);

  useEffect(() => {
    if (isWalletConnected) {
      if (selectedProvider === connectorNames.metamask) {
        metaMask.activate();
      } else if (selectedProvider === connectorNames.walletconnect) {
        walletConnect.activate();
      }
    }
  }, [isWalletConnected]);

  return w3Context;
};
