import { useRef, useLayoutEffect, useState } from "react";
import gsap from "gsap";
import styles from "./Header.module.css";
import useUIStore from "../../Store/UIStore";

import clsx from "clsx";

import HamburgerIcon from "../../Components/HamburgerIcon";

import HONTEXT from "../../Assets/images/hontext.png";
import TierButton from "../TierButton";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "../../Hooks/useMediaQuery";
import nftIcon from "../../Assets/images/nft.png";
import MetaverLink from "../MetaverseLink";
import { useDetectGPU } from "@react-three/drei";

export default function Header(props) {
  const { setTermsofService } = useUIStore(state => ({
    setTermsofService: state.setTermsofService,
  }));

  const [menuOpened, setMenuOpened] = useState(false);
  const IconRef = useRef();
  const navigate = useNavigate();

  const { isMobile } = useDetectGPU();

  const handleMenuClick = e => {
    setMenuOpened(!menuOpened);
    document.body.classList.toggle("no-scroll");
  };

  const handleClick = e => {
    navigate("/metaverse");
  };

  const handleTermsClick = e => {
    console.log("terms of service clicked");
    handleMenuClick();
    setTermsofService(true);
  };

  return (
    <header className={styles.header}>
      <HamburgerIcon onClick={handleMenuClick} open={menuOpened} />
      <div className={clsx(styles.backdrop, menuOpened ? styles.active : styles.deactive)}>
        <div className={styles.menuItems}>
          <div className={styles.topCover}>...</div>
          <a
            target="_blank"
            href="https://traderjoexyz.com/avalanche/trade?inputCurrency=AVAX&outputCurrency=0xed2b42d3c9c6e97e11755bb37df29b6375ede3eb"
          >
            {" "}
            <span className={styles.menuItem}>buy hon token</span>
          </a>
          <span className={clsx(styles.menuItem, styles.disabled)}>login/logout</span>
          <span className={clsx(styles.menuItem, styles.disabled)}>my nft lands</span>
          <span className={clsx(styles.menuItem, styles.disabled)}>marketplace</span>
          <a href="https://heroesofnft.com">
            <span className={styles.menuItem}>heroes of nft</span>
          </a>
          <a href="https://app.heroesofnft.com/">
            <span className={styles.menuItem}>web application</span>
          </a>
          <a href="https://docs.heroesofnft.com/">
            <span className={styles.menuItem}>whitepaper</span>
          </a>
          <span className={styles.menuItem} onClick={handleTermsClick}>
            terms of service
          </span>
          <div className={styles.bottomCover}>...</div>
        </div>
      </div>
      <img className={styles.icon} ref={IconRef} src={HONTEXT} alt="heroes of nft" />
      {isMobile ? null : <MetaverLink onClick={handleClick} className={styles.metaverse} />}
    </header>
  );
}
