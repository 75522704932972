import { useCallback } from "react";
import create from "zustand";
import { getScrollCameraAction } from "../Utils/helpers";

const calcIsMobile = () => window.innerWidth <= 430;

const store = create((set, get) => ({
  activeCamera: calcIsMobile() ? "camera_scroll_mobile" : "camera_scroll",
  mainCamera: calcIsMobile() ? "camera_scroll_mobile" : "camera_scroll",
  direction: "IDLE",
  isMobile: calcIsMobile(),
  zoomedTier: null,
  hovered: null,
  isVideoOpened: false,
  displayContent: true,
  isMenuOpened: false,
  displayZoomButtons: false,
  isZoomingCompleted: false,
  isModelsLoading: true,
  isModelsReady: false,
  isLoaderScreenMounted: true,
  isOverlayScreenMounted: false,
  overlayContent: null,
  isWalletModalOpened: false,
  isWeb3Connecting: false,
  scrollProgress: 0,
  isComingSoon: false,
  purchaseTier: null,
  isPurchasePanelOpened: false,
  isShipRotating: true,
  isLottieOpen: true,
  isLightsOn: true,
  shakeIntensity: undefined,
  selectedTierModel: null,
  isShipVisible: true,
  isInside: false,
  isCookieVisible: localStorage.getItem("cookie") === null,
  cameraAction: getScrollCameraAction(),
  saleSuccess: false,
  intro: false,
  isNftListOpen: false,
  isTermsOpen: false,
  isFallbackLoaded: false,
  updateActiveCamera: camera => set(_ => ({ activeCamera: camera })),
  updateDirection: direction => set(_ => ({ direction: direction })),
  zoomTo: tier => {
    return set(_ => ({
      zoomedTier: tier,
    }));
  },
  zoomOut: () =>
    set(_ => {
      return {
        isZoomingCompleted: false,
        isShipRotating: true,
        zoomedTier: null,
      };
    }),
  setZoomedTier: value => set(() => ({ zoomedTier: value })),
  hover: tier => set(() => ({ hovered: tier })),
  openVideo: () => set(() => ({ isVideoOpened: true })),
  closeVideo: () => set(() => ({ isVideoOpened: false })),
  toggleMenu: () => set(state => ({ isMenuOpened: !state.isMenuOpened })),
  activateMainCamera: () =>
    set(state => ({ activeCamera: state.isMobile ? "camera_scroll_mobile" : "camera_scroll" })),
  updateButtons: value => set(() => ({ displayZoomButtons: value })),
  setZoomCompleted: () => set(() => ({ isZoomingCompleted: true })),
  togglePurchaseInView: () =>
    set(state => {
      if (state.zoomedTier === null) {
        return { displayZoomButtons: !state.displayZoomButtons };
      }
      return {};
    }),
  startLoadingModels: () => set(() => ({ isModelsLoading: true })),
  endModelsLoading: () => set(() => ({ isModelsLoading: false, isOverlayScreenMounted: true })),
  unMountLoaderScreen: () => set(() => ({ isLoaderScreenMounted: false })),
  mountOverlayScreen: () => set(() => ({ isOverlayScreenMounted: true })),
  setOverlayContent: el => set(() => ({ overlayContent: el })),
  openWalletModal: () => set(() => ({ isWalletModalOpened: true })),
  closeWalletModal: () => set(() => ({ isWalletModalOpened: false })),
  setWeb3Connection: value => set(() => ({ isWeb3Connecting: value })),
  updateProgress: value => set(() => ({ scrollProgress: value })),
  updateDimensions: () =>
    set(() => ({
      isMobile: calcIsMobile(),
    })),
  updateComingSoon: value =>
    set(() => ({
      isComingSoon: value,
    })),
  /**
   *
   * @param {{tier: string, open: boolean}} options
   */
  openPurchase: options => {
    set(state => ({
      isPurchasePanelOpened: true,
      purchaseTier: state.zoomedTier,
    }));
  },
  closePurchase: () => {
    set(() => ({
      isPurchasePanelOpened: false,
      purchaseTier: null,
    }));
  },
  showLottie: () => set(() => ({ isLottieOpen: true })),
  hideLottie: () => set(() => ({ isLottieOpen: false })),
  lightsOn: () => set(() => ({ isLightsOn: true })),
  lightsOff: () => set(() => ({ isLightsOn: false })),
  shake: value => set(() => ({ shakeIntensity: value })),
  rotate: value => set(() => ({ isShipRotating: value })),
  setSelectedTierModel: value => set(() => ({ selectedTierModel: value })),
  updateShipVisibility: value => set(() => ({ isShipVisible: value })),
  moveIn: value => set(() => ({ isInside: value })),
  updateCookie: value => set(() => ({ isCookieVisible: value })),
  setCameraAction: value =>
    set(() => ({
      cameraAction: value,
    })),
  setSaleSuccess: value => set(() => ({ saleSuccess: value })),
  initIntro: () => set(() => ({ intro: true })),
  toggleNftList: () => set(state => ({ isNftListOpen: !state.isNftListOpen })),
  setTermsofService: value => set(() => ({ isTermsOpen: value })),
  setFallbackLoaded: value => set(() => ({ isFallbackLoaded: value })),
}));

export default selector => store(useCallback(selector, []));
