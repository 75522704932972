import { ethers } from "ethers";

export const tiers = {
  TIER1: 1,
  TIER2: 2,
  TIER3: 3,
  TIER4: 4,
  NULL: null,
};

export const IS_IOS = () => /iPhone/.test(navigator?.userAgent ?? "");

export const INSIDE_CAMERAS = {
  tier1: {
    into: {
      name: "camera_into_tier1_2_Orientation",
      animation: "camera_into_tier1",
      key: "_into_",
    },
    in: {
      name: "camera_in_tier1_2_Orientation",
      animation: "camera_in_tier1",
      key: "_in_",
    },
    waiting: {
      name: "camera_waiting_tier1_2_Orientation",
      key: "_waiting_",
    },
  },
};

export const ROOM_TEXTS = {
  tier1: {
    info: "The Tier 1 Metaspheres are designed to offer the highest level of luxury and comfort, with their golden code and spacious areas. Tier 1 lands will have more customization options compared to other tiers. They are ideal for organizing tournaments and entertaining guests. The price for these Metaspheres is stable at 100,000 HON in the current round of Land Sale.",
    detail:
      "There are 250 Tier 1 Metaspheres in Phosphania, but only 50 of them will be available in the current round of land sale.",
    price: 100000,
    size: "50 m²",
  },
  tier2: {
    info: "Get a taste of royalty in your own land with Tier 2 Metaspheres, featuring a purple code with hints of nature to pay homage to the old world's inhabitants. The price for these Metaspheres is stable at 24,000 HON in the current round of Land Sale.",
    detail:
      "There are 750 Tier 1 Metaspheres in Phosphania, but only 150 of them will be available in the current round of land sale.",
    price: 24000,
    size: "20 m²",
  },
  tier3: {
    info: "Find peace in the oceanic blue hues of Tier 3 Metaspheres, inspired by the vast oceans of the old world. These areas are designed to evoke a sense of calm and tranquility in their inhabitants. The price for these Metaspheres is stable at 12,000 HON in the current round of Land Sale.",
    detail:
      "There are 1250 Tier 1 Metaspheres in Phosphania, but only 250 of them will be available in the current round of land sale.",
    price: 12000,
    size: "8 m²",
  },
  tier4: {
    info: "Experience the closest thing to the fresh air of the old world with Tier 4 Metaspheres, featuring an authentic green color and unique plants. The price for these Metaspheres is stable at 8,000 HON in the current round of Land Sale.",
    detail:
      "There are 1750 Tier 1 Metaspheres in Phosphania, but only 350 of them will be available in the current round of land sale.",
    price: 8000,
    size: "4 m²",
  },
};

export const connectorNames = {
  metamask: "metamask",
  walletconnect: "walletconnect",
};

export const CAMERA_ACTIONS = {
  camera_in_tier1: "camera_in_tier1",
  camera_in_tier2: "camera_in_tier2",
  camera_in_tier3: "camera_in_tier3",
  camera_in_tier4: "camera_in_tier4",
  camera_into_tier1: "camera_into_tier1",
  camera_into_tier2: "camera_into_tier2",
  camera_into_tier3: "camera_into_tier3",
  camera_into_tier4: "camera_into_tier4",
  camera_scroll: "camera_scroll",
  camera_to_tier1: "camera_to_tier1",
  camera_to_tier2: "camera_to_tier2",
  camera_to_tier3: "camera_to_tier3",
  camera_to_tier4: "camera_to_tier4",
  camera_waiting_tier1: "camera_waiting_tier1",
  camera_waiting_tier2: "camera_waiting_tier2",
  camera_waiting_tier3: "camera_waiting_tier3",
  camera_waiting_tier4: "camera_waiting_tier4",
};

export const ROOM_PER_TIERPAGE = {
  tier1: 15,
  tier2: 20,
  tier3: 25,
  tier4: 30,
};

let now = new Date();
let end = new Date();
end.setMonth(end.getMonth() + 2);

const convertToWei = value => {
  const val = ethers.utils.parseUnits(value.toString(), "ether");
  return val.toString();
};

export const landTiers = [
  {
    tierId: Number(0),
    name: "Tier 0 Land",
    price: convertToWei(1),
    count: Number(25),
    remainingAmount: Number(25),
    saleEnabled: false,
    startDate: String(
      Date.UTC(now.getFullYear(), now.getMonth() - 1, now.getDay(), now.getHours(), 0, 0, 0) / 1000
    ),
    endDate: String(
      Date.UTC(end.getFullYear(), end.getMonth(), end.getDay(), end.getHours(), 0, 0, 0) / 1000
    ),
    tokenIdRange: [0, 24],
  },
  {
    tierId: Number(1),
    name: "Tier 1 Land",
    price: convertToWei(1),
    count: Number(250),
    remainingAmount: Number(250),
    saleEnabled: true,
    startDate: String(
      Date.UTC(now.getFullYear(), now.getMonth() - 1, now.getDay(), now.getHours(), 0, 0, 0) / 1000
    ),
    endDate: String(
      Date.UTC(end.getFullYear(), end.getMonth(), end.getDay(), end.getHours(), 0, 0, 0) / 1000
    ),
    tokenIdRange: [25, 274],
  },
  {
    tierId: Number(2),
    name: "Tier 2 Land",
    price: convertToWei(1),
    count: Number(750),
    remainingAmount: Number(750),
    saleEnabled: true,
    startDate: String(
      Date.UTC(now.getFullYear(), now.getMonth() - 1, now.getDay(), now.getHours(), 0, 0, 0) / 1000
    ),
    endDate: String(
      Date.UTC(end.getFullYear(), end.getMonth(), end.getDay(), end.getHours(), 0, 0, 0) / 1000
    ),
    tokenIdRange: [275, 1024],
  },
  {
    tierId: Number(3),
    name: "Tier 3 Land",
    price: convertToWei(1),
    count: Number(1250),
    remainingAmount: Number(1250),
    saleEnabled: true,
    startDate: String(
      Date.UTC(now.getFullYear(), now.getMonth() - 1, now.getDay(), now.getHours(), 0, 0, 0) / 1000
    ),
    endDate: String(
      Date.UTC(end.getFullYear(), end.getMonth(), end.getDay(), end.getHours(), 0, 0, 0) / 1000
    ),
    tokenIdRange: [1025, 2274],
  },
  {
    tierId: Number(4),
    name: "Tier 4 Land",
    price: convertToWei(1),
    count: Number(1750),
    remainingAmount: Number(1750),
    saleEnabled: true,
    startDate: String(
      Date.UTC(now.getFullYear(), now.getMonth() - 1, now.getDay(), now.getHours(), 0, 0, 0) / 1000
    ),
    endDate: String(
      Date.UTC(end.getFullYear(), end.getMonth(), end.getDay(), end.getHours(), 0, 0, 0) / 1000
    ),
    tokenIdRange: [2275, 4024],
  },
];
