import { createRoot } from "react-dom/client";
import "./styles.css";
import { Web3ReactProvider } from "@web3-react/core";

import { metaMask, metaMaskHooks, walletConnect, walletConnectHooks } from "./Utils/connector";
import ContractContextProvider from "./Context/ContractContext";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { OWNER_OFS } from "./Graphql";
import { Suspense, lazy } from "react";
import Header from "./Components/Header";

const Metaverse = lazy(() => import("./Metaverse"));
const App = lazy(() => import("./App"));

const client = new ApolloClient({
  uri: process.env.REACT_APP_INDEXER_URL,
  cache: new InMemoryCache(),
});

client
  .query({
    query: OWNER_OFS,
  })
  .then(res => {
    console.log("prefetching data");
  })
  .catch(err => {
    console.log("error fetching data");
  });

const connectors = [
  [metaMask, metaMaskHooks],
  [walletConnect, walletConnectHooks],
];

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <BrowserRouter>
    <Web3ReactProvider connectors={connectors}>
      <ContractContextProvider>
        <ApolloProvider client={client}>
          <Routes>
            <Route
              path={"/metaverse"}
              element={
                <Suspense fallback={null}>
                  <Metaverse />
                </Suspense>
              }
            />
            <Route
              path="*"
              element={
                <Suspense>
                  <App fallback={null} />
                </Suspense>
              }
            />
          </Routes>
        </ApolloProvider>
      </ContractContextProvider>
    </Web3ReactProvider>
  </BrowserRouter>
);
