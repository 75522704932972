import clsx from "clsx";
import styles from "./TierButton.module.css";
export default function OutlinedButton({ text, textColor, background, className, ...rest }) {
  return (
    <button
      style={{
        color: textColor,
      }}
      className={clsx(styles.buttonContainer, styles.outlinedButton, className)}
      {...rest}
    >
      {text}
    </button>
  );
}
