import { useRef, useState } from "react";
import styles from "./Hamburger.module.css";
import clsx from "clsx";

const HamburgerIcon = ({ onClick, ...props }) => {
  const containerRef = useRef(null);
  const [buttonState, setButtonState] = useState("Initial");

  const hover = next => {
    if (buttonState === "Clicked") return;
    setButtonState(next);
  };

  const handleClick = e => {
    onClick && onClick(e);
    setButtonState(buttonState === "Clicked" ? "Hovered" : "Clicked");
  };

  const firstStyles = styles[`first${buttonState}`];
  const secondStyles = styles[`second${buttonState}`];
  const thirdStyles = styles[`third${buttonState}`];

  return (
    <div
      className={styles.container}
      ref={containerRef}
      onMouseEnter={() => hover("Hovered")}
      onMouseLeave={() => hover("Initial")}
      onClick={handleClick}
      {...props}
      tabIndex="1"
      role="button"
      aria-pressed={buttonState === "Clicked" ? "true" : "false"}
    >
      <svg
        width="54"
        height="42"
        viewBox="0 0 54 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Frame 1245">
          <path
            id="Vector"
            d="M44 8L40 12H10L14 8H44Z"
            fill="#009b9b"
            className={clsx(styles.vectorPath, firstStyles)}
          />
          <path
            id="Vector_2"
            d="M33.5 19L29.51 23H10L14 19H33.5Z"
            fill="#009b9b"
            className={clsx(styles.vectorPath, secondStyles)}
          />
          <path
            id="Vector_3"
            d="M23.01 30L19.01 34H10L14 30H23.01Z"
            fill="#009b9b"
            className={clsx(styles.vectorPath, thirdStyles)}
          />
        </g>
      </svg>
    </div>
  );
};
export default HamburgerIcon;
