import styles from "./MetaverseLink.module.css";
import RR from "../../Assets/images/rr.svg";
import clsx from "clsx";
export default function MetaverLink({ className, ...props }) {
  return (
    <button className={clsx(styles.button, className)} {...props}>
      <div>
        <span>experience metaverse</span>
        <span>
          <img src={RR} alt="" />
        </span>
      </div>
    </button>
  );
}
