import { useWeb3React, useWeb3 } from "@web3-react/core";
import { useEffect, useMemo } from "react";
import { useContext, createContext } from "react";
import useNetworkStore from "../Store/NetworkStore";
import { Contract } from "ethers";
import { metaMaskHooks } from "../Utils/connector";

import LandAbi from "../Contracts/LandToken.json";
import HonAbi from "../Contracts/HonToken.json";
import { useConnector } from "../Hooks/useWeb3";

const LandAddress = process.env.REACT_APP_LAND_CONTRACT_ADDRESS;
const HonAddress = process.env.REACT_APP_HON_CONTRACT_ADDRESS;

const ContractContext = createContext();

const { Provider } = ContractContext;

const ContractContextProvider = ({ children }) => {
  const { connector, isActive } = useWeb3React();

  const { provider, signer } = useConnector();

  const { landContract, honContract } = useMemo(() => {
    if (signer) {
      const _hon = new Contract(HonAddress, HonAbi, signer);
      const _land = new Contract(LandAddress, LandAbi, signer);
      return {
        landContract: _land,
        honContract: _hon,
      };
    } else {
      return {
        landContract: null,
        honContract: null,
      };
    }
  }, [signer]);

  /* useEffect(() => {
    if (isWalletConnected) {
      connector.activate();
    }
  }, [isWalletConnected]); */
  return <Provider value={{ hon: honContract, land: landContract }}>{children}</Provider>;
};
export default ContractContextProvider;
/**
 *
 * @param {"hon" | undefined | null} selector
 * @returns {Contract | undefined | null}
 */
export const useContract = selector => {
  if (selector) {
    return useContext(ContractContext)[selector];
  } else {
    return useContext(ContractContext).land;
  }
};

export const useProvider = () => useContext(ContractContext).provider;
export const useSigner = () => useContext(ContractContext).provider?.getSigner();
